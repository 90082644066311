<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="6">
        <h1>Rewards Management</h1>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Rewards"
          single-line
          hide-details
          class="pt-0"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" sm="4">
        <v-autocomplete
          v-model="selectedCountry"
          :items="rewardCountries"
          item-text="country"
          item-value="country"
          label="Filter by Country"
          clearable
          outlined
          dense
          @change="filterRewards"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="filteredRewards"
            :search="search"
            :loading="loading"
            loading-text="Loading rewards... Please wait"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Rewards List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addReward" class="mr-2">
                  <v-icon left>mdi-plus</v-icon>
                  Add Reward
                </v-btn>
                <v-btn color="primary" @click="getRewards">
                  <v-icon left>mdi-refresh</v-icon>
                  Refresh
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:item.color="{ item }">
              <v-chip :color="item.color" small class="ma-2">
                {{ item.color }}
              </v-chip>
            </template>

            <template v-slot:item.status="{ item }">
              <v-switch
                v-model="item.status"
                :true-value="'active'"
                :false-value="'inactive'"
                :loading="statusLoading[item._id]"
                @change="toggleStatus(item)"
                color="primary"
                hide-details
              ></v-switch>
            </template>

            <template v-slot:item.createdAt="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      search: "",
      loading: false,
      statusLoading: {},
      rewards: [],
      filteredRewards: [],
      selectedCountry: null,
      headers: [
        { text: "Name", value: "name" },
        { text: "Points", value: "points" },
        { text: "Color", value: "color" },
        { text: "Description", value: "description" },
        { text: "Method", value: "cashoutMethod" },
        { text: "Country", value: "country" },
        { text: "Status", value: "status" },
        { text: "Created", value: "createdAt" },
      ],
    };
  },

  computed: {
    rewardCountries() {
      const countries = [...new Set(this.rewards.map(reward => reward.country))];
      return countries.map(country => ({ country }));
    }
  },

  mounted() {
    this.getRewards();
  },

  methods: {
    async getRewards() {
      try {
        this.loading = true;
        this.$setLoader();

        const response = await service.getAllRewards();
        this.rewards = response.data.data;
        this.filterRewards();

        this.$disableLoader();
        this.loading = false;
      } catch (error) {
        console.error("Error fetching rewards:", error);
        this.$disableLoader();
        this.loading = false;
      }
    },

    filterRewards() {
      if (this.selectedCountry) {
        this.filteredRewards = this.rewards.filter(
          (reward) => reward.country === this.selectedCountry
        );
      } else {
        this.filteredRewards = [...this.rewards];
      }
    },

    async toggleStatus(item) {
      try {
        this.$setLoader();

        await service.updateRewardStatus({
          rewardId: item._id,
          status: item.status,
        });

        this.getRewards();
      } catch (error) {
        console.error("Error updating status:", error);
        item.status = item.status === "active" ? "inactive" : "active";
      } finally {
        this.$disableLoader();
      }
    },

    addReward() {
      this.$router.push({ name: "MobileV2AddReward" });
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
  },
};
</script>

<style scoped>
h1 {
  color: #1976d2;
  margin-bottom: 20px;
}

.v-card {
  margin-top: 20px;
}
</style>